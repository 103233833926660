<template>
	<div style="margin: 10px;">

		<!-- 全屏透明的loading加载start -->
		<div id="pageLoading" v-if="loading" style="position: fixed;top:0;left: 0;width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;z-index: 999999;">
			<i class="el-icon-loading" style="font-size: 25px;"></i>
		</div>
		<!-- 全屏透明的loading加载end -->

		<div style="background-color: #fff;border-radius: 3px;">
			<div style="position: sticky;top:0;z-index: 999;background-color: #fff;">

				<!--带折叠的搜索start-->
				<div style="padding-top: 10px;">
					<el-form size="mini" style="display: flex;flex-wrap: wrap;" ref="searchForm" :model="searchForm" inline label-width="100px">

						<!--收起时显示的搜索项start  4个搜索项为一行-->
						<div v-show="!isShowCollapse" style="width: 80%;display: flex;">
							

							<el-form-item label="订单号" style="width: 23%;display: flex;">
								<el-input type="text" v-model="searchForm.order_num" placeholder="输入订单号" clearable />
							</el-form-item>
							<el-form-item label="订单状态" style="width: 23%;display: flex;">
								<el-select v-model="searchForm.state" placeholder="选择订单状态" clearable>
									<!-- 订单状态 (0: 待付款, 1: 待发货, 2: 已发货, 3: 已完成/确认收货, 4: 已退款, 5: 申请退款, 6: 审核通过并等待客户退货中, 7: 客户提交了退货物流等待商家退款,8:客户申请退款审核拒绝/拒绝退款,9:商家收到货后 同意退款/已退款 10.商家收到货后拒绝退款(商家自行和客户协商处理) ，,发货后不允许申请退货或退款一直到确认收货后再允许退款,状态1未发货可以申请退款，状态3已完成可以申请退款， -->

									<el-option :value="0" label="待付款"></el-option>
									<el-option :value="1" label="待发货"></el-option>
									<el-option :value="2" label="已发货"></el-option>
									<el-option :value="3" label="已完成"></el-option>
									<el-option :value="4" label="已取消"></el-option>

									<el-option :value="5" label="申请退货"></el-option>
									<el-option :value="6" label="待填写退货物流"></el-option>
									<el-option :value="7" label="已填写退货物流"></el-option>
									<el-option :value="8" label="拒绝退货"></el-option>
									<el-option :value="9" label="已退货同意退款"></el-option>
									<el-option :value="10" label="已退货拒绝退款"></el-option>

								</el-select>
							</el-form-item>
							



						</div>
						<!--收起时显示的搜索项end-->

						<!--展开时显示的搜索项start  4个搜索项为一行-->
						<div v-show="isShowCollapse" style="display: flex;flex-wrap: wrap;width: 100%;">

						

							<el-form-item label="订单号" style="width: 23%;display: flex;" label-width="120px">
								<el-input type="text" v-model="searchForm.order_num" placeholder="输入订单号" clearable />
							</el-form-item>
							<el-form-item label="订单状态" style="width: 23%;display: flex;" label-width="120px">
								<el-select v-model="searchForm.state" placeholder="选择订单状态" clearable>

									<el-option :value="0" label="待付款"></el-option>
									<el-option :value="1" label="待发货"></el-option>
									<el-option :value="2" label="已发货"></el-option>
									<el-option :value="3" label="已完成"></el-option>
									<el-option :value="4" label="已取消"></el-option>

									<el-option :value="5" label="申请退货"></el-option>
									<el-option :value="6" label="待填写退货物流"></el-option>
									<el-option :value="7" label="已填写退货物流"></el-option>
									<el-option :value="8" label="拒绝退货"></el-option>
									<el-option :value="9" label="已退货同意退款"></el-option>
									<el-option :value="10" label="已退货拒绝退款"></el-option>

								</el-select>
							</el-form-item>

						</div>
						<!--展开时显示的搜索项end-->

						<div :style="{'padding-right':isShowCollapse?'15px':'0','width':isShowCollapse?'100%':'20%','justify-content': isShowCollapse?'flex-end':'center' }" style="height: 30px; display: flex;margin: 0;">
							<div style="flex-shrink: 0;margin-right: 20px;display: flex;justify-content: center;align-items: center;cursor:pointer;color: rgb(154, 126, 156);font-size: 14px;line-height: 20px; display: flex;margin-left: 20px;" @click="isShowCollapseF()">
								<span v-if="!isShowCollapse">展开</span>
								<span v-else>收起</span>
								<span style="margin-left: 5px;font-size: 20px;line-height: 20px;" :style="{transform:isShowCollapse?'rotate(180deg)':'rotate(0deg)'}">∨</span>
							</div>
							<el-button round size="small" style="margin-right: 10px" @click="handleSearch" type="primary" icon="ios-search">搜索</el-button>
							<el-button round size="small" @click="handleReset" :style="{'margin-right': isShowCollapse?'10px':'28px'}">重置</el-button>
						</div>

					</el-form>
				</div>
				<!--带折叠的搜索end-->




			</div>

			<div>



				<!-- 按钮start -->
				<div style="display: flex;justify-content: space-between;position: relative;z-index: 9;padding-left: 30px;">
					
					<div>
						<el-button round size="small" type="primary" @click="exportXFnByHTML"  style="margin-right: 25px;">订单导出</el-button>
					</div>


				</div>
				<!-- 按钮end -->




				<el-table :height="tableHeight" style="margin-top: -35px;" @selection-change="handleSelect" ref="tablesi" :data="tableData" @on-delete="handleDelete">

					<el-table-column style="width: 100%;">
						<template slot-scope="{ row, index }">

							<div class="titleFont" style="font-size: 10px;color: rgb(51, 51, 51);text-align: left;padding-left: 20px;background-color: #f8f8f8;padding-top: 5px;padding-bottom: 5px;border-radius: 20px;">

								<span>订单状态:</span>

								<!-- 订单状态 (0: 待付款, 1: 待发货, 2: 已发货, 3: 已完成/确认收货, 4: 已退款, 5: 申请退款, 6: 审核通过并等待客户退货中, 7: 客户提交了退货物流等待商家退款,8:客户申请退款审核拒绝/拒绝退款,9:商家收到货后 同意退款/已退款 10.商家收到货后拒绝退款(商家自行和客户协商处理) ，,发货后不允许申请退货或退款一直到确认收货后再允许退款,状态1未发货可以申请退款，状态3已完成可以申请退款， -->

								<span v-if="row.list[0].state ==0" style="margin-left: 6px;color: #2d8cf0;font-weight: bold;">待付款</span>
								<span v-else-if="row.list[0].state ==1" style="margin-left: 6px;color: #FA9803;font-weight: bold;">待发货</span>
								<span v-else-if="row.list[0].state ==2" style="margin-left: 6px;color: #2d8cf0;font-weight: bold;">已发货</span>
								<span v-else-if="row.list[0].state ==3" style="margin-left: 6px;color: limegreen;font-weight: bold;">已完成</span>
								<span v-else-if="row.list[0].state ==4" style="margin-left: 6px;color: limegreen;font-weight: bold;">已取消</span>

								<!-- <span v-else-if="row.list[0].state ==5&& row.list[0].old_state==1" style="margin-left: 6px;color: red;font-weight: bold;">申请退款中</span>-->
								
								<span v-else-if="row.list[0].state ==5&& row.list[0].old_state==3" style="margin-left: 6px;color: red;font-weight: bold;">申请退货中</span>
								<span v-else-if="row.list[0].state ==6" style="margin-left: 6px;color: red;font-weight: bold;">待填写退货物流</span>
								<span v-else-if="row.list[0].state ==7" style="margin-left: 6px;color: red;font-weight: bold;">已填写退货物流</span>
								<span v-else-if="row.list[0].state ==8" style="margin-left: 6px;color: red;font-weight: bold;">拒绝退货</span>
								<span v-else-if="row.list[0].state ==9" style="margin-left: 6px;color: limegreen;font-weight: bold;">已退货同意退款</span>
								<span v-else-if="row.list[0].state ==10" style="margin-left: 6px;color: red;font-weight: bold;">已退货拒绝退款</span>

								<span style="margin-left: 25px;">订单号:</span><span style="margin-left: 6px;">{{row.list[0].order_num}}</span>

								<span v-if="row.list[0].logistics_name" style="margin-left: 25px;">快递公司:</span><span style="margin-left: 6px;">{{row.list[0].logistics_name}}</span>

								<span v-if="row.list[0].logistics_ns" style="margin-left: 25px;">快递单号:</span><span style="margin-left: 6px;">{{row.list[0].logistics_ns}}</span>

								<span v-if="row.list[0].logistics_price" style="margin-left: 25px;">运费金额:</span><span style="margin-left: 6px;">{{row.list[0].logistics_price}}</span>

								<span v-if="row.list[0].logistics_date" style="margin-left: 25px;">提交快递时间:</span><span style="margin-left: 6px;">{{row.list[0].logistics_date}}</span>

								<span style="margin-left: 25px;">下单时间:</span><span style="margin-left: 6px;">{{row.list[0].date}}</span>

							</div>

							<div class="tableTem" style="display: flex;align-items: center;margin-left: 20px;margin-bottom: 10px;">

								<!-- 商品列表start -->
								<div class="avatar" style="padding-top: 15px;">
									<!-- 商品start -->
									<div style="width: 300px;padding-right: 10px;max-height: 140px;overflow-y: auto;border-right: 1px solid rgb(237, 237, 237);">

										<div v-for="(item,index) in row.list" :key="index" style="margin: 0px 3px;margin-top: 10px;  display: flex;justify-content: space-between;">
											<div style="width: 200px;color: #333333;">
												<div style="display: flex;">
													<img :src="item.cover_img" style="flex-shrink: 0;width: 60px;height: 60px;margin-right: 5px;border-radius: 5px;cursor: pointer;" @click="handlePreView(item.cover_img)" />
													<div style="text-align: left;">
														<div style="font-size: 12px;margin-right: 10px;line-height: 15px;margin-bottom: 6px;">
															<el-tooltip :content="item.name" placement="right">
																<div style="width: 150px;overflow: hidden;text-overflow: ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;">{{item.name}}</div>

															</el-tooltip>
														</div>
														<div style="font-size: 10px;line-height: 14px;margin-right: 10px;color: #888;">{{item.goods_sku_name}}</div>
													</div>
												</div>

											</div>

											<div style="text-align: right;font-size: 13px;color: #888;">
												<div>
													<span style="font-size: 10px;margin-right: 3px;">¥</span>{{item.price}}
												</div>
												<div>
													{{item.count}}件
												</div>
											</div>
										</div>


									</div>
									<!-- 商品end -->

									<div style="width: 150px;border-right: 1px solid #ededed;color: rgb(51, 51, 51);margin-left: 10px;">

										<div class="titleFont" style="text-align: left;">
											<span style="margin-right: 5px;">订单总金额:</span>
											<el-tooltip :content="row.list[0].goodsToalPrice+''" placement="right"><span class="content">¥{{row.list[0].goodsToalPrice}}</span></el-tooltip>
										</div>
										
										<div class="titleFont" style="text-align: left;">
											<span style="margin-right: 5px;">总支付金额:</span>
											<el-tooltip :content="row.list[0].total_pay_price?row.list[0].total_pay_price+'':'未支付'" placement="right"><span class="content">{{row.list[0].total_pay_price?'¥'+row.list[0].total_pay_price:'未支付'}}</span></el-tooltip>
										</div>



									</div>


								</div>
								<!-- 订单列表end -->
								<ul  :style="{'width':isMobile?mobileWidth+'px':'','overflow-x':isMobile?'auto':'','overflow-y':isMobile?'hidden':'','flex-wrap':isMobile?'nowrap':''}">

									<li style="border-left: none;">
										<div class="content">
											<el-tooltip :content="row.list[0].user.name" placement="right">
												<div class="dian">{{row.list[0].user.name}}</div>
											</el-tooltip>
										</div>
										<div class="titleFont dian">用户名</div>
									</li>


									<li>
										<div class="content">
											<el-tooltip :content="row.list[0].user.mobile+''" placement="right">
												<div class="dian">{{row.list[0].user.mobile?row.list[0].user.mobile:'无'}}</div>
											</el-tooltip>
										</div>
										<div class="titleFont dian">用户手机号</div>
									</li>

									<li>
										<div class="content">
											<el-tooltip :content="row.list[0].addr_name" placement="right">
												<div class="dian">{{row.list[0].addr_name}}</div>
											</el-tooltip>
										</div>
										<div class="titleFont dian">收货人</div>
									</li>

									<li>
										<div class="content">
											<el-tooltip :content="row.list[0].addr_mobile+''" placement="right">
												<div class="dian">{{row.list[0].addr_mobile}}</div>
											</el-tooltip>
										</div>
										<div class="titleFont dian">收货电话</div>
									</li>


									<li>
										<div class="content">
											<el-tooltip :content="row.list[0].addr" placement="right">
												<div class="dian">{{row.list[0].addr}}</div>
											</el-tooltip>
										</div>
										<div class="titleFont dian">收货地址</div>
									</li>


									<!-- 		<li>
										<el-tooltip :content="row.created_at+''" placement="right">

											<div class="content dis">
												{{row.created_at | timeBreak2}}
											</div>
										</el-tooltip>
										<div class="titleFont">创建时间</div>
									</li> -->







								</ul>



								<div style="width: 100px;display: flex;justify-content: start;align-items: center;">
									<el-dropdown trigger="hover" style="width: 80px;">
										<el-button size="mini" round type="primary">
											操作<i class="el-icon-arrow-down el-icon--right"></i>
										</el-button>
										<el-dropdown-menu slot="dropdown">
											<div class="dis" style="flex-direction: column;">

												<!-- 订单状态 (0: 待付款, 1: 待发货, 2: 已发货, 3: 已完成/确认收货, 4: 已退款, 5: 申请退款, 6: 审核通过并等待客户退货中, 7: 客户提交了退货物流等待商家退款,8:客户申请退款审核拒绝/拒绝退款,9:商家收到货后 同意退款/已退款 10.商家收到货后拒绝退款(商家自行和客户协商处理) ，,发货后不允许申请退货或退款一直到确认收货后再允许退款,状态1未发货可以申请退款，状态3已完成可以申请退款， -->
												<el-dropdown-item>
													<el-button type="success" size="mini" round @click="showOrderDetailModal(row)">查看详情
													</el-button>
												</el-dropdown-item>
												<!-- 添加物流进行发货按钮 -->
												<el-dropdown-item v-if="row.list[0].state ==1">
													<el-button type="success" size="mini" round @click="showAddWuLiu(row)">添加物流
													</el-button>
												</el-dropdown-item>
												
												<el-dropdown-item v-if="row.list[0].state ==1">
													<el-button type="success" size="mini" round @click="tuiKuanCeDan(row)">退款撤单
													</el-button>
												</el-dropdown-item>

												<!-- 查看物流按钮 -->
												<el-dropdown-item v-if="row.list[0].logistics_ns">
													<el-button type="success" size="mini" round @click="showWuLiu(row)">查看物流
													</el-button>
												</el-dropdown-item>

												<!-- 申请退款start -->

												<!-- 
													未发货同意退款 
												<el-dropdown-item v-if="row.list[0].state ==5 && row.list[0].old_state==1">
													<el-button type="success" size="mini" round @click="editStateByOrderNum(row,4)">同意退款
													</el-button>
												</el-dropdown-item>
												-->

												<!-- 已收货并同意申请退货 -->
												<el-dropdown-item v-if="row.list[0].state ==5 && row.list[0].old_state==3">
													<el-button type="success" size="mini" round @click="editStateByOrderNum(row,6)">同意退货
													</el-button>
												</el-dropdown-item>
												<!-- 已收货并拒绝申请退或 -->
												<el-dropdown-item v-if="row.list[0].state ==5 && row.list[0].old_state==3">
													<el-button type="danger" size="mini" round @click="juJueTuiHuo(row,8)">拒绝退货
													</el-button>
												</el-dropdown-item>

												<!-- 客户已收货并且商家收到退货同意退款 -->
												<el-dropdown-item v-if="(row.list[0].state ==7||row.list[0].state ==10)&& row.list[0].old_state==3">
													<el-button type="success" size="mini" round @click="editStateByOrderNum(row,9)">同意退货退款
													</el-button>
												</el-dropdown-item>
												<!-- 客户已收货并且商家收到退货拒绝退款 -->
												<el-dropdown-item v-if="row.list[0].state ==7&& row.list[0].old_state==3">
													<el-button type="danger" size="mini" round @click="juJueTuiHuo(row,10)">拒绝退货退款
													</el-button>
												</el-dropdown-item>

												<!-- 申请退款end -->


												<el-dropdown-item  v-if="row.list[0].state !=0&&row.list[0].state !=3">
													<el-button type="success" size="mini" round @click="editStateByOrderNum(row,3)">帮用户确认收货
													</el-button>
												</el-dropdown-item>



											</div>


										</el-dropdown-menu>
									</el-dropdown>

								</div>


							</div>

						</template>
					</el-table-column>


				</el-table>
			</div>

			
			
			<!--分页start-->
			<el-row type="flex" justify="end" align="middle" class="page" style="margin-top: 10px;margin-right: 50px;padding-bottom: 10px;">
			<!--pageSize-->
				<el-pagination background layout="prev, pager, next" :current-page="pageNumber" :page-size="pageSize" :total="total" @current-change="changePage"></el-pagination>
				 <span style="font-size: 13px;color: #999;">共 {{total}} 条</span> 
			</el-row>
			<!--表格end-->


		</div>
		
		<!-- 商品详情弹窗start -->
		<el-dialog :close-on-click-modal="false" :visible.sync="isShowOrderDetailModal" title="" width="80%">
			<order-detail ref="orderDetail"></order-detail>
		
		</el-dialog>
		<!-- 商品详情弹窗end -->

		

		<!-- 添加物流弹窗start -->
		<el-dialog center :visible.sync="isShowAddWuLiu" title="添加物流" width="600px">

			<div style="text-align: center;">
				<div class="dis" style="margin: 20px 0;">
					<span>物流公司:</span>
					<el-select size="mini" v-model="addWuLiuByShippingName" placeholder="请选择物流名称" clearable style="width: 250px;margin-left: 10px;">

						<el-option v-for="(item,index) in expressList" :value="item.logistics_name" :label="item.logistics_name"></el-option>

					</el-select>


				</div>
				<div class="dis" style="margin: 20px 0;">
					<span>物流单号:</span>
					<el-input size="mini" v-model="addWuLiuByShippingNo" placeholder="请输入物流单号" style="width: 250px;margin-left: 10px;" />
				</div>


			</div>
			<div class="dis" style="justify-content: flex-end;">

				<el-button type="primary" size="mini" round @click="addWuLiuOKBtn">确定
				</el-button>
			</div>
		</el-dialog>
		<!-- 添加物流弹窗end -->


		<!-- 物流信息弹窗start -->
		<el-dialog center :visible.sync="isShowWuLiu" title="物流信息" width="700px">

			<div>

				<!-- 物流信息start -->
				<div style="padding: 20px;border-top: 1rpx solid #fff;padding-top: 0;">

					<div class="dis" style="font-weight: bold; font-size: 14px;padding: 10px 0;color: #393939;padding-bottom: 20px;">
						<div class="dis">
							<img :src="currentWuLiu.logo" style="width: 50px;height: 50px;border-radius: 50%;margin-right: 20px;"/>
							<div>{{currentWuLiu.expName}}</div>
							<div style="margin-left: 10px;">{{currentWuLiu.number}}</div>

						</div>

					</div>

					<!-- 物流时间轴 -->
					<div>

						<template v-if="currentWuLiu.list&&currentWuLiu.list.length>0">
							<div v-for="(item,index) in currentWuLiu.list" :key="index">

								<div style="position: relative;display: flex;align-items: center;color:#9A9A9A;padding-left: 10px;">

									<div :style="{'background-color': index==0?'red':'#9A9A9A'}" style="width: 10px;height: 10px;border-radius: 50%;position: absolute;top:25px;left:5px;transform: translateY(-50%);"></div>

									<div style="padding:15px;padding-left: 20px; border-left: 1px solid rgba(207, 207, 207, 0.3);padding-right: 10px;">
										<div style="margin-bottom: 10px;">{{item.time}}</div>
										{{item.status}}
									</div>

								</div>

							</div>
						</template>
						<div v-else style="text-align: center;margin: 50px 0;color: #9A9A9A;">暂无物流信息~</div>

					</div>
				</div>

			</div>
			<!-- 物流信息end -->
		</el-dialog>
		<!--物流信息弹出end -->



		<el-dialog v-model="delModal.show" title="提示" @on-ok="deleteOK" @on-cancel="deleteCancel">
			<p>你确定要删除数据吗？</p>
		</el-dialog>

		<!-- :width="preView.width" -->
		<el-dialog title="预览" :visible.sync="preView.show" width="30%" footer-hide>
			<img :src="preView.imgUrl " v-if="preView.show" style="width: 100%">
		</el-dialog>




	</div>
</template>

<script>
	import {
		ajax
	} from '../../util/ajax.js';
	import excel from '@/components/excel/excel.js'
	import exportObj from '@/components/excel/excelByHtml.js'
	import orderDetail from './orderDetail.vue'
	import _ from 'lodash'
	import config from '@/util/config.js'

	export default {
		name: 'orderList',
		components: {

			orderDetail
		},
		data() {
			return {
				isMobile: window.isMobile,
				mobileWidth: window.mobileWidth + 100,
				
				
				isShowOrderDetailModal: false, //是否显示订单详情窗口
				
				expressList: [], //物流选项列表
				isShowCollapse: false, //是否展开搜索项
				daoruExcel: [], //导入excel

				currentAddWuLiu: '', //当前行
				isShowAddWuLiu: false, //是否显示添加物流弹窗
				addWuLiuByShippingNo: '', //添加的物流单号
				addWuLiuByShippingName: '', //添加的物流公司名称

				currentWuLiu: '', //当前显示的物流数据
				isShowWuLiu: false, //是否显示物流信息

				tableHeight: 0, //表格高度
				modal: {
					show: false,
					title: '详情'
				},
				preView: {
					show: false,
					imgUrl: '',
					width: 600
				},

				delModal: {
					show: false,
					guid: ''
				},
				row2modal: {},
				loading: true,
				searchForm: {
					state:""
				},
				pageNumber: 1, // 当前页数
				pageSize: 10, // 页面大小
				total: 0,


				tableData: [],

			}
		},

		filters: {
			//把 2021-07-30 11:07:36 格式为 07-30
			//								11:07
			//使用格式 <p>{{row.stateChangeTime | timeBreak(0)}}</p>    <p>{{row.stateChangeTime | timeBreak(1)}}</p> 
			timeBreak(time, type, from) {
				if (time) {
					return type == 0 ? (from == undefined ? time.substr(5, 5) : time.substr(0, 10)) : (type == 2 ? time
					.substr(0, 10) : (from == undefined ? time.substr(11, 5) : time.substr(11, 8)));
				} else {
					return time;
				}
			},

			timeBreak2(val) {

				let v = val.split(' ')[0];

				return v;
			},

		},

		created() {
			this.getTableHeight();
		},
		mounted() {
			localStorage.removeItem("oldOrderTotal")

			//挂载window.onresize事件(动态设置table高度)
			let _this = this;
			window.onresize = () => {
				if (_this.resizeFlag) {
					clearTimeout(_this.resizeFlag);
				}
				_this.resizeFlag = setTimeout(() => {
					_this.getTableHeight();
					_this.resizeFlag = null;
				}, 100);
			};

			if (this.$route.query.state) { //待发货状态的
				this.searchForm.state = Number(this.$route.query.state);
			}
		
			this.getLogisticsList();
			this.initData()
		},

		methods: {

			//获取物流列表
			async getLogisticsList(){
				//获取物流选项列表
				let res2 = await ajax('/getLogisticsList', 'GET', {});
				this.expressList = res2.data.data.list;
			},
			async initData() {
				this.loading = true
				var searchForm = this.searchForm;

			
				// 要用三个等，因为有的值时0
				if (searchForm.order_num === "") { //select未选中为空字符串
					searchForm.order_num = null; //为null后就不会作为http请求参数了带给后端了
				}

				// 要用三个等，因为有的值时0
				if (searchForm.state === "") { //select未选中为空字符串
					searchForm.state = null; //为null后就不会作为http请求参数了带给后端了
				}




				var pageSize = this.pageSize
				var pageNumber = this.pageNumber

				let param = {
					...searchForm,
					count: pageSize,
					page: pageNumber
				};
				//获取订单列表的接口start
				let res = await ajax('/getOrderList', 'GET', param);
				const list = res.data.data.list;
				let arr = [];
				for (let key in list) {
					arr.push({
						'list': list[key]
					});
				}

				this.tableData = arr;
				console.log(arr.length)
				this.total = res.data.data.total
				this.loading = false;
				//获取订单列表的接口end

				

			},
			
			//导出订单
			async exportXFnByHTML() {
				
				console.log(1)
				
				this.loading = true
				var searchForm = this.searchForm;

			
				// 要用三个等，因为有的值时0
				if (searchForm.order_num === "") { //select未选中为空字符串
					searchForm.order_num = null; //为null后就不会作为http请求参数了带给后端了
				}

				// 要用三个等，因为有的值时0
				if (searchForm.state === "") { //select未选中为空字符串
					searchForm.state = null; //为null后就不会作为http请求参数了带给后端了
				}


				let param = {
					...searchForm,
					count: 100000,
					page: 1
				};
				//获取订单列表的接口start
				let res = await ajax('/getOrderList', 'GET', param);
				const list = res.data.data.list;
				

				
				//获取订单列表的接口end
				if(list.length==0){
					this.loading = false;
					this.$message.error('暂无导出信息~')
					return;
				}
				
				
				
				let headerArr = ["下单时间","商品信息","订单号","订单状态","订单总金额","用户名","用户手机号","快递公司","快递单号","运费金额","收货人","收货电话","收货地址"];//列名
				let bodyArr = [];//列值
				
				console.log("list=====",list)
				
				let listArr = [];
				for(let key in list){
					listArr.push(list[key]);
				}
				
				for(let orderLiat of listArr){
				
					let obj = { date:"",goodsArr:[],order_num:"",state:"",goodsToalPrice:"",userName:"",userMobile:"",logistics_name:"",logistics_ns:"",logistics_price:"",addr_name:"",addr_mobile:"",addr:"" };
					
					
					
					obj.date = orderLiat[0].date;//下单时间
					
					for(let goods of orderLiat){
					
						obj.goodsArr.push({
							goodsName:"商品名："+goods.name,
							goods_sku_name:"规格名："+ (goods.goods_sku_name?goods.goods_sku_name:"无"),
							price:"售价："+goods.price+"元",
							count:"数量："+goods.count+'件'
						});
					}//商品信息
					
					obj.order_num = orderLiat[0].order_num;//订单号
					if(orderLiat[0].state==0){
						obj.state = "待付款";//订单状态
					}else if(orderLiat[0].state==1){
						obj.state = "待发货";//订单状态
					}else if(orderLiat[0].state==2){
						obj.state = "已发货";//订单状态
					}else if(orderLiat[0].state==3){
						obj.state = "已完成";//订单状态
					}else if(orderLiat[0].state==4){
						obj.state = "已取消";//订单状态
					}else if(orderLiat[0].state==5 && orderLiat[0].old_state==3){
						obj.state = "申请退货中";//订单状态
					}else if(orderLiat[0].state==6){
						obj.state = "待填写退货物流";//订单状态
					}else if(orderLiat[0].state==7){
						obj.state = "已填写退货物流";//订单状态
					}else if(orderLiat[0].state==8){
						obj.state = "拒绝退货";//订单状态
					}else if(orderLiat[0].state==9){
						obj.state = "已退货同意退款";//订单状态
					}else if(orderLiat[0].state==10){
						obj.state = "已退货拒绝退款";//订单状态
					}
					
					obj.goodsToalPrice = orderLiat[0].goodsToalPrice;//订单总金额
					obj.userName = orderLiat[0].user.name;//用户名
					obj.userMobile = orderLiat[0].user.mobile?orderLiat[0].user.mobile:'无';//用户手机号
					obj.logistics_name = orderLiat[0].logistics_name?orderLiat[0].logistics_name:"无";//快递公司
					obj.logistics_ns = orderLiat[0].logistics_ns?orderLiat[0].logistics_ns:"无";//快递单号
					obj.logistics_price = orderLiat[0].logistics_price;//运费金额
					obj.addr_name = orderLiat[0].addr_name;//收货人
					obj.addr_mobile = orderLiat[0].addr_mobile;//收货电话
					obj.addr = orderLiat[0].addr;//收货地址
					bodyArr.push(obj);
				}
				
				  // tHeader和tbody的数据需要一一对应
				    let tHeader = headerArr;//列头
					let tbody = bodyArr;//每列的列值
					//后面两个参数是图片宽度和高度,不建议导出图片，请求太慢
					exportObj.export2Excel(tHeader, tbody, '订单数据.xls')
					this.loading = false;





			},

			//退款审核通过
			async refund(row, status) {
				let text = '';
				if (status == 6) {
					text = '确定同意退款吗？';
				}
				if (status == 8) {
					text = '确定拒绝退款吗？';
				}
				if (status == 7) {
					text = '确定退款吗？';
				}

				this.$confirm(text, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {

					this.loading = true;
					let res = await ajax('/setState', 'GET', {
						id: row.id,
						state: status,
						old_state: row.old_state
					});
					this.loading = false;
					this.initData();
					this.$message({
						type: 'success',
						message: '成功!'
					});


				}).catch(() => {

				});

			},



			//打开商品详情弹窗
			showOrderDetailModal(row) {
			
				this.isShowOrderDetailModal = true; //是否显示订单详情窗口
				setTimeout(() => {
					this.$refs.orderDetail.initData(row);
				}, 300)
			
			},
			//计算table高度(动态设置table高度)
			getTableHeight() {
				let tableH = 190; //距离页面下方的高度
				let tableHeightDetil = window.innerHeight - tableH;

				if (tableHeightDetil <= 300) {
					this.tableHeight = 300;
				} else {
					this.tableHeight = window.innerHeight - tableH;
				}
			},


			//展开搜索项
			isShowCollapseF() {
				this.isShowCollapse = !this.isShowCollapse;
			},
			//导入
			handleBeforeUpload(file) {


				this.loading = true
				const fileExt = file.name.split('.').pop().toLocaleLowerCase()
				if (fileExt === 'xlsx' || fileExt === 'xls') {
					this.readFile(file)
				} else {
					this.$Notice.warning({
						title: '文件类型错误',
						desc: '文件：' + file.name + '不是EXCEL文件，请选择后缀为.xlsx或者.xls的EXCEL文件。'
					})
					this.loading = false
				}
				return false
			},














			//显示添加物流
			showAddWuLiu(row) {
				this.addWuLiuByShippingNo = ''; //添加的物流单号
				this.addWuLiuByShippingName = ''; //添加的物流公司名称
				this.currentAddWuLiu = row; //当前行对象
				this.isShowAddWuLiu = true; //是否显示添加物流信息

			},

			//同意退款
			async editStateByOrderNum(row, state) {
				let that = this;
				this.$confirm("确定吗?", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {

					that.loading = true;
					let res = await ajax('/editStateByOrderNum', 'GET', {
						order_num: row.list[0].order_num,
						state
					});

					that.loading = false;
					that.$message({
						type: 'success',
						message: '成功!'
					});
					that.initData();




				}).catch(() => {

				});


			},
			
			//退款撤单
			async tuiKuanCeDan(row, state) {
				let that = this;
				this.$prompt('请输入原因', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(async ({
					value
				}) => {
					
					that.loading = true;
					let res = await ajax('/canelOrder', 'POST', {
						order_num: row.list[0].order_num,
						jujue_remark:value
					});
					
					that.loading = false;
					that.$message({
						type: 'success',
						message: '成功!'
					});
					that.initData();
					
					
				}).catch(() => {
					
				});
			
			
			},

			//拒绝退货
			async juJueTuiHuo(row, state) {
				let that = this;
				this.$prompt('请输入原因', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(async ({
					value
				}) => {
					
					that.loading = true;
					let res = await ajax('/juJueTuiHuo', 'POST', {
						order_num: row.list[0].order_num,
						state,
						jujue_remark:value
					});
					
					that.loading = false;
					that.$message({
						type: 'success',
						message: '成功!'
					});
					that.initData();
					
					
				}).catch(() => {
					
				});


			},

			//点击添加物流弹窗上的确认按钮
			async addWuLiuOKBtn() {
				if (!this.addWuLiuByShippingName.trim()) {

					this.$message.error('请输入物流公司名称')
					return;
				}
				if (!this.addWuLiuByShippingNo.trim()) {
					this.$message.error('请输入物流单号')
					return;
				}

				this.loading = true;
				let res = await ajax('/addOrderLogistics', 'POST', {
					order_num: this.currentAddWuLiu.list[0].order_num,
					logistics_name: this.addWuLiuByShippingName,
					logistics_ns: this.addWuLiuByShippingNo
				});

				this.loading = false;
				this.$message({
					type: 'success',
					message: '成功!'
				});
				this.initData();
				this.isShowAddWuLiu = false;

			},

			//显示物流信息
			async showWuLiu(row) {
				console.log('row',row)
				this.loading = true;
				let res = await ajax('/getWuLiuInfo', 'POST', {
					no: row.list[0].logistics_ns
				});
				const express = JSON.parse(res.data.data.list);
				
				this.loading = false;


				//没有物流
				if (!express) {
					this.$message('暂无物流信息')
					return;
				}
				
			
				console.log('物流信息',express.result)
				this.currentWuLiu = express.result; //当前显示的物流数据
				this.isShowWuLiu = true; //是否显示物流信息


			},
			showOrderModal(list) {
				this.isShowOrderModal = true;
				this.currentOrderListObj = list;
			},

			handlePreView(url, width) {
				this.preView.imgUrl = url
				this.preView.show = true
				if (width) {
					this.preView.width = width
				}
			},
			modalOk() {
				this.modal.show = false
			},
			modalClose(isRefresh) {
				this.modal.show = false
				this.row2modal = {}
				if (isRefresh) {
					this.initData()
				}
			},
			handleSelect(e) {
				this.selectData = e;
			},


			show(row) {
				this.row2modal = _.cloneDeep(row)
				this.modal.show = true
			},
			add() {
				this.row2modal = {}
				this.modal.show = true
			},

			changePage(pageNumber) {
				this.tableData = []; //这种可以让滚动条回到顶部
				this.pageNumber = pageNumber
				this.initData()
			},




			handleDelete(row) {
				this.delModal.guid = row.guid
				this.delModal.show = true
			},
			deleteOK() {
				this.loading = true
				let guid = this.delModal.guid
				api.del({
					guid
				}).then(res => {
					if (res.data.state && res.data.state === 'ok') {
						this.$Message.success('删除成功')
						this.initData()
					} else {
						this.$Message.error(res.data.msg)
					}
					this.delModal.guid = ''
					this.delModal.show = false
					this.loading = false
				}).catch(err => {
					this.loading = false
					this.$Message.error(err)
					this.delModal.show = false
					this.loading = false
				})
			},
			deleteCancel() {
				this.delModal.guid = ''
				this.delModal.show = false
			},


			/**
			 * 搜索
			 */
			handleSearch() {
				this.pageNumber = 1
				this.total = 0;
				this.initData()
			},

			/**
			 * 重置
			 */
			handleReset() {
				this.$refs.searchForm.resetFields()
				this.searchForm = {};

				this.pageNumber = 1
				this.total = 0;
				this.pageSize = 10
				this.tableData = []; //表格数据修改为空
				this.initData()
			},

		}
	}
</script>

<style scoped="scoped">
	.tableTem ul {
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;

	}

	.tableTem ul li {
		list-style: none;

		width: 110px;
		min-height: 60px;
		border-left: 1px solid #ededed;
		border-bottom: 1px solid #ededed;
		margin-bottom: -1px;
		display: flex;
		justify-content: end;
		align-items: center;
		flex-direction: column;
		padding: 3px;


	}

	>>>.el-table tbody tr:hover>td {
		background-color: transparent !important;
	}

	.tableTem .content {

		padding-top: 3px;
		font-size: 14px;

		font-weight: 600;
	}



	.tableTem .avatar img {
		border-radius: 50%;
		width: 60px;
		height: 60px;
	}

	.tableTem .avatar {
		/* height: 120px; */

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.tableTem .dian {
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100px;
	}

	.titleFont {
		font-size: 12px !important;
		flex-shrink: 0;
		line-height: 25px;
		color: rgb(136, 136, 136);
	}





	.sortLine {
		display: flex;
		align-items: center;
		min-height: 35px;
	}

	.sortItem {
		padding: 0 20px;
		height: 100%;
		color: rgb(156, 156, 156);
		text-align: center;
		line-height: 35px;
		cursor: pointer;
	}

	.sortItem:hover {
		background-color: #19be6b !important;
		color: #fff !important;
	}

	.sortLine .sortItem:last-child {
		border-right: none;
	}

	>>>.ivu-table-cell {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	>>>.el-table .el-table-column--selection .cell {
		padding: 0 !important;
		padding-left: 20px !important;
	}
</style>
