<template>
	<div v-if="isShowPage">

		<div class="order-detail">


			<div class="title">用户信息</div>
			<div class="order-information " style="margin-bottom: 50px;">
				<div class="order-information-contentOne">
					<!-- 用户start -->
					<div class="contentOne-content" >
						<div class="contentOne-content-title" style="margin-left: 1em;">用户id：</div>
						<div class="contentOne-content-text text-num">{{row.list[0].user.id}}</div>
					
					</div>
					
					<div class="contentOne-content" style="margin-top: 22px;">
						<div class="contentOne-content-title">用户头像：</div>
						<img :src="row.list[0].user.img" style="margin-top: -10px; width: 50px;height: 50px;border-radius: 50%;cursor: pointer;margin-right: 10px;" @click="handlePreView(row.list[0].user.img)" />

					</div>
					<div class="contentOne-content">
						<div class="contentOne-content-title">用户昵称：</div>
						<div class="contentOne-content-text text-num">{{row.list[0].user.name}}</div>

					</div>



					<div class="contentOne-content">
						<!-- 0: 未知, 1: 男, 2: 女 -->
						<div class="contentOne-content-title">用户性别：</div>
						<div v-if="row.list[0].user.sex==0" class="contentOne-content-text text-num">未知</div>
						<div v-if="row.list[0].user.sex==1" class="contentOne-content-text text-num">男</div>
						<div v-if="row.list[0].user.sex==2" class="contentOne-content-text text-num">女</div>
					</div>

					<div class="contentOne-content">
						<div class="contentOne-content-title">收货人名：</div>
						<div class="contentOne-content-text text-num">{{row.list[0].addr_name}}</div>

					</div>
					<div class="contentOne-content">
						<div class="contentOne-content-title">收货电话：</div>
						<div class="contentOne-content-text text-num">{{row.list[0].addr_mobile}}</div>

					</div>
					<div class="contentOne-content">
						<div class="contentOne-content-title" style="flex-shrink: 0;">收货地址：</div>
						<div class="contentOne-content-text text-num">{{row.list[0].addr}}</div>

					</div>

					<!-- 用户end -->

				</div>


				<div class="order-information-contentOne">


				</div>




			</div>






			<div class="title">订单状态</div>
			<div class="order-information ">
				<div class="order-information-contentOne">

					<div class="contentOne-content">
						<div class="contentOne-content-title">订单状态：</div>
						<div class="contentOne-content-text text-num" style="color: rgb(255,106,0);">

							<div v-if="row.list[0].state ==0">待付款</div>
							<div v-else-if="row.list[0].state ==1" >待发货</div>
							<div v-else-if="row.list[0].state ==2" >已发货</div>
							<div v-else-if="row.list[0].state ==3" >已完成</div>
							<div v-else-if="row.list[0].state ==4">已取消</div>
							
							<!-- <div v-else-if="row.list[0].state ==5&& row.list[0].old_state==1">申请退款中</div> -->
							
							<div v-else-if="row.list[0].state ==5&& row.list[0].old_state==3">申请退货中</div>
							<div v-else-if="row.list[0].state ==6" >待填写退货物流</div>
							<div v-else-if="row.list[0].state ==7" >已填写退货物流</div>
							<div v-else-if="row.list[0].state ==8" >拒绝退货</div>
							<div v-else-if="row.list[0].state ==9" >已退货同意退款</div>
							<div v-else-if="row.list[0].state ==10" >已退货拒绝退款</div>
							
							

						</div>
					</div>
					
					
					
					<div v-if=" (row.list[0].state ==8 || row.list[0].state ==10) && row.list[0].jujue_remark">
						<div class="contentOne-content">
							<div class="contentOne-content-title" style="flex-shrink: 0;">拒绝原因：</div>
							<div class="contentOne-content-text text-num">{{row.list[0].jujue_remark}}</div>
						</div>
					
					</div>
					
					<div v-if=" row.list[0].state ==4 && row.list[0].jujue_remark">
						<div class="contentOne-content">
							<div class="contentOne-content-title" style="flex-shrink: 0;">退款原因：</div>
							<div class="contentOne-content-text text-num">{{row.list[0].jujue_remark}}</div>
						</div>
					
					</div>


					<!-- 退款物流start -->
					<div v-if="row.list[0].state==5||row.list[0].state==6||row.list[0].state==7||row.list[0].state==8||row.list[0].state==9||row.list[0].state==10">
						<div>
							<div  class="contentOne-content">
								<div class="contentOne-content-title" >申请时间：</div>
								<div class="contentOne-content-text text-num">{{row.list[0].shenqingtuihuo_date}}</div>
							
							</div>
							<div class="contentOne-content">
								<div class="contentOne-content-title" style="flex-shrink: 0;">退货原因：</div>
								<div class="contentOne-content-text text-num">{{row.list[0].tui_remark}}</div>
							</div>
							<div class="contentOne-content">
								<div class="contentOne-content-title" style="flex-shrink: 0;">退货图片：</div>
								<div class="contentOne-content-text text-num">
									
									<img v-for="(item,index) in row.list[0].tui_img ? row.list[0].tui_img.split(','):[]" :src="item" style="width: 50px;height: 50px;border-radius: 5px;cursor: pointer;margin-right: 10px;" @click="handlePreView(item)"/>
								</div>
							</div>
							
						</div>
						<div  v-if="row.list[0].state==7||row.list[0].state==10||row.list[0].state==9">

							<div class="contentOne-content">
								<div class="contentOne-content-title" style="flex-shrink: 0;margin-left: -2em;" >退货快递名称：</div>
								<div class="contentOne-content-text text-num">{{row.list[0].logistics_name}}</div>
							</div>

						
							<div class="contentOne-content">
								<div class="contentOne-content-title" style="flex-shrink: 0;margin-left: -2em;">退货快递单号：</div>
								<div class="contentOne-content-text text-num">{{row.list[0].logistics_ns}}</div>
							</div>
							<div class="contentOne-content">
								<div class="contentOne-content-title" style="flex-shrink: 0;margin-left: -2em;">提交快递时间：</div>
								<div class="contentOne-content-text text-num">{{row.list[0].logistics_date}}</div>
							</div>

					</div>
					</div>
					<!-- 退货物流end -->




				</div>
			</div>
		</div>


		<div class="order-detail">
			<div class="title">订单详情</div>
			<div class="order-information ">
				<div class="order-information-contentOne">
					
					
					
					<div v-if="row.list[0].shouhuo_date" class="contentOne-content">
						<div class="contentOne-content-title">收货时间：</div>
						<div class="contentOne-content-text text-num">{{row.list[0].shouhuo_date}}</div>
					
					</div>
					
					

					<div class="contentOne-content">
						<div class="contentOne-content-title" style="margin-left:1em;">订单号：</div>
						<div class="contentOne-content-text text-num">{{row.list[0].order_num}}</div>
					</div>

		
					<div class="contentOne-content">
						<div class="contentOne-content-title">订单金额：</div>
						<div class="contentOne-content-text text-num">{{row.list[0].goodsToalPrice}}</div>
					</div>


					<block  v-if=" !(row.list[0].state==7||row.list[0].state==10||row.list[0].state==9)">
						<div class="contentOne-content">
							<div class="contentOne-content-title">快递名称：</div>
							<div class="contentOne-content-text text-num">{{row.list[0].logistics_name}}</div>
						
						</div>
						
						<div class="contentOne-content">
							<div class="contentOne-content-title">快递单号：</div>
							<div class="contentOne-content-text text-num">{{row.list[0].logistics_ns}}</div>
						
						</div>
						
						<div class="contentOne-content">
							<div class="contentOne-content-title">运费金额：</div>
							<div class="contentOne-content-text text-num">{{row.list[0].logistics_price}}</div>
						
						</div>
						
						<div class="contentOne-content">
							<div class="contentOne-content-title" style="margin-left: -2em;">提交快递时间：</div>
							<div class="contentOne-content-text text-num">{{row.list[0].logistics_date}}</div>
						</div>
					
					</block>
					
					
					
				</div>

				<!-- 发货的物流信息start v-if="row.order.express_name" -->
				<!-- <div class="order-information-contentOne">
					
				</div> -->

				<!-- 发货的物流信息end -->


			</div>

		</div>


		<div class="shop-information">
			<div class="title">商品信息</div>


			<div class="shop-information-table">
				<table class="" lay-filter="parse-table-order-product" lay-skin="line">
					<thead>
						<tr class="table-trOne">
							<th style="width: 500px;">商品</th>
							<th>售价</th>
							<th>数量</th>

						</tr>
					</thead>
					<tbody>
						<template v-for="(item,index) in row.list">
							<tr class="table-trTow">
								<td>
									<div style="display: flex;margin: 10px 3px;padding-left: 20px;padding-right: 20px;">
										<img :src="item.cover_img" style="width: 50px;height: 50px;margin-right: 10px;border-radius: 5px;cursor: pointer;" @click="handlePreView(item.cover_img)" />
										<div style="text-align: left;">
											<div style="font-size: 13px;margin-right: 10px;">{{item.name}}</div>
											<div style="font-size: 12px;margin-right: 10px;color: #888;">{{item.goods_sku_name}}</div>
										</div>
									</div>
								</td>
							
								<td>{{item.price}}</td>
								<td>{{item.count}}</td>
								
							</tr>
						</template>
					</tbody>
				</table>
				
				<div style="border-top: 1px solid rgb(238, 238, 238);"></div>
				


			</div>
		</div>

		<el-dialog title="预览" :modal="false" width="400px" :visible.sync="preShow">
			<img :src="preImgUrl" style="width: 100%">
		</el-dialog>


		<!-- 全屏透明的loading加载start -->
		<div id="pageLoading" v-if="loading" style="position: fixed;top:0;left: 0;width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;z-index: 999999;">
			<i class="el-icon-loading" style="font-size: 25px;"></i>
		</div>
		<!-- 全屏透明的loading加载end -->


	</div>
</template>

<script>
	import {
		ajax
	} from '../../util/ajax.js';
	export default {
		name: '',
		components: {},
		data() {
			return {
				row: {}, //当前显示的订单详情对象
				preImgUrl: '',
				preShow: false,
				loading: false,
				isShowPage:false
			}
		},
		mounted() {

		},
		methods: {

			async initData(row) {
				console.log("row=",row)
				this.row=row;
				this.isShowPage = true;

			},
			handlePreView(url) {
				this.preImgUrl = url
				this.preShow = true
			},
		}
	}
</script>

<style scoped>
	.title {
		color: #333333;
		margin-bottom: 24px;
		width: 100%;
		height: 16px;
		line-height: 16px;
		padding-left: 10px;
		border-left: 3px solid rgb(254, 106, 0);
		box-sizing: border-box;
		font-size: 15px;
		font-weight: bold;
	}

	.order-detail {
		padding: 15px;
		box-sizing: border-box;
		background: white;
	}

	.order-information>div:nth-child(1) {
		border-left: none;
	}

	.order-information-contentOne {
		width: 50%;
		height: 100%;
		padding: 0 48px;
		box-sizing: border-box;
	}

	.order-information {

		width: 100%;
		min-height: 40px;
		display: flex;
	}

	.contentOne-content {
		display: flex;
		font-size: 14px;
		color: rgb(164, 164, 164);
		margin-bottom: 14px;
	}

	.shop-information {
		width: 100%;
		background: white;
		padding: 15px;
		box-sizing: border-box;
		margin-top: 15px;
	}

	.shop-information-table {
		width: 100%;
		padding: 0 48px;
		box-sizing: border-box;
		margin-bottom: 10px;
	}

	.shop-information-table>table {
		width: 100%;
		border: 1px solid rgb(238, 238, 238);
		border-bottom: none;
	}

	.table-trThree {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid rgb(238, 238, 238);
		max-width: 100%;
		min-height: 40px;
		padding: 16px 28px;
		box-sizing: border-box;
		border: 1px solid rgb(238, 238, 238);
	}

	.table-settlement {
		margin: 10px 0 20px;
		color: #333333;
		text-align: right;
	}

	.table-trOne {
		height: 48px;
		background: rgb(245, 245, 245);
	}

	th {
		text-align: center;
		/* padding-left: 28px; */
		box-sizing: border-box;
		font-weight: 500;
		color: #333333;
		border-right: 1px solid rgb(238, 238, 238);
	}

	tbody {
		display: table-row-group;
		vertical-align: middle;
		border-color: inherit;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	.table-trTow {
		width: 100%;
		height: 60px;
		border-top: 1px solid rgb(238, 238, 238);
	}

	.table-trTow>td {
		text-align: center;
		/* padding-left: 28px; */
		box-sizing: border-box;
		font-weight: 500;
		color: #333333;
		border-right: 1px solid rgb(238, 238, 238);
	}

	.table-trThree>div {
		font-weight: 500;
		color: #333333;
	}

	.table-trThree>div>p:nth-child(1) {
		margin-bottom: 8px;
	}

	.table-trThree>div>p {
		color: rgb(164, 164, 164);
	}

	.table-trThree>div>p {
		display: flex;
		align-items: center;
	}

	.table-trThree>div>p>span {
		color: #333333;
	}

	.table-settlement>span:nth-child(2) {
		color: rgb(255, 106, 0);
		font-size: 15px;
	}
	
	.contentOne-content-title{
		flex-shrink: 0;
	}
</style>
