// 
		//使用方法在需要导出excel表格的xx.vue文件中编写:
		//import exportObj from './exportX/exportX.js';
		//   // tHeader和tbody的数据需要一一对应
		//     let tHeader = [
		// 		'鲜花',
		// 		'颜色',
		// 		'照片'
		// 	]
		// 	let tbody = [
		// 		{
		// 			name: '玫瑰花',
		// 			color: '红色',
		// 			pic: 'https://res.redsay.net/admin/39/1624616246846.jpg'
		// 		},
		// 		{
		// 			name: '菊花',
		// 			color: '黄色',
		// 			pic: 'https://res.redsay.net/admin/39/1624616246846.jpg'
		// 		},
		// 		{
		// 			name: '牵牛花',
		// 			color: '紫色',
		// 			pic: 'https://res.redsay.net/admin/39/1624616246846.jpg'
		// 		},
		// 		{
		// 			name: '梅花',
		// 			color: '白色',
		// 			pic: 'https://res.redsay.net/admin/39/1624607271578.jpg'
		// 		},
		// 		{
		// 			name: '桃花花',
		// 			color: '粉色',
		// 			pic: 'https://res.redsay.net/admin/39/1624607271578.jpg'
		// 		}
		// 	]
		// 	exportObj.export2Excel(tHeader, tbody, '导出的文件名 或者 文件名.后缀名')
		
		let idTmr;
		const getExplorer = () => {
		  let explorer = window.navigator.userAgent;
		  //ie
		  if (explorer.indexOf("MSIE") >= 0) {
			return 'ie';
		  }
		  //firefox
		 
		  else if (explorer.indexOf("Firefox") >= 0) {
			return 'Firefox';
		  }
		  //Chrome
		  else if (explorer.indexOf("Chrome") >= 0) {
			return 'Chrome';
		  }
		  //Opera
		  else if (explorer.indexOf("Opera") >= 0) {
			return 'Opera';
		  }
		  //Safari
		  else if (explorer.indexOf("Safari") >= 0) {
			return 'Safari';
		  }
		}
		// 判断浏览器是否为IE
		const exportToExcel = (data,name) => {
		 
		  // 判断是否为IE
		  if (getExplorer() == 'ie') {
			tableToIE(data, name)
		  } else {
			tableToNotIE(data,name)
		  }
		}
		 
		const Cleanup = () => {
		  window.clearInterval(idTmr);
		}
		 
		// ie浏览器下执行
		const tableToIE = (data, name) => {
		  let curTbl = data;
		  let oXL = new ActiveXObject("Excel.Application");
		 
		  //创建AX对象excel
		  let oWB = oXL.Workbooks.Add();
		  //获取workbook对象
		  let xlsheet = oWB.Worksheets(1);
		  //激活当前sheet
		  let sel = document.body.createTextRange();
		  sel.moveToElementText(curTbl);
		  //把表格中的内容移到TextRange中
		  sel.select;
		  //全选TextRange中内容
		  sel.execCommand("Copy");
		  //复制TextRange中内容
		  xlsheet.Paste();
		  //粘贴到活动的EXCEL中
		 
		  oXL.Visible = true;
		  //设置excel可见属性
		 
		  try {
			let fname = oXL.Application.GetSaveAsFilename("Excel.xls", "Excel Spreadsheets (*.xls), *.xls");
		  } catch (e) {
			print("Nested catch caught " + e);
		  } finally {
			oWB.SaveAs(fname);
		 
			oWB.Close(savechanges = false);
			//xls.visible = false;
			oXL.Quit();
			oXL = null;
			// 结束excel进程，退出完成
			window.setInterval("Cleanup();", 1);
			idTmr = window.setInterval("Cleanup();", 1);
		  }
		}
		 
		// 非ie浏览器下执行
		const tableToNotIE = (function() {
		  // 编码要用utf-8不然默认gbk会出现中文乱码
		  let uri = 'data:application/vnd.ms-excel;base64,',
			template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta charset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
			base64 = function(s) {
			  return window.btoa(unescape(encodeURIComponent(s)));
		 
			},
		 
			format = (s, c) => {
			  return s.replace(/{(\w+)}/g,
				(m, p) => {
				  return c[p];
				})
			}
		  return (table, name) => {
			let ctx = {
			  worksheet: name,
			  table
			}
		 
			//创建下载
			let link = document.createElement('a');
			link.setAttribute('href', uri + base64(format(template, ctx)));
		 
			link.setAttribute('download', name);
		 
		 
			// window.location.href = uri + base64(format(template, ctx))
			link.click();
		  }
		})()
		 
		// 导出函数,这种方式就是把一段html标签代码导出到excel文件中
		//坑点:这种方式导出的excel文件在编写15位以上的数字后再用js读取这个excel文件会出现15位以上数字变为科学计数格式
		//导出的excel文件中其实就是这一段<table>标签代码并且excel文件会自动解析html标签代码显示为表格
		//js 中导出excel 较长数字串会变成科学计数法的解决方法为: 在下面的<td>修改为 tbody += '<td style="text-align:center;mso-number-format:\@;">' + row[key] + '</td>'   <td style="mso-number-format:\@;">100821199909091234</td>       增加了  style="mso-number-format:\@;"  样式后 可以解决 ，
		const export2Excel = (theadData, tbodyData, dataname,width,height) => {
		 
		  let re = /http/ // 字符串中包含http,则默认为图片地址
		  let th_len = theadData.length // 表头的长度
		  let tb_len = tbodyData.length // 记录条数
		  
		  width =  width?width:100;// 设置图片大小
		  height = height?height:100;
		 
		
		  // 添加表头信息,并且修改这段html标签代码可以改变excel文件中的表格的显示格式或样式 例如 表格合并,颜色,背景色
		  let thead = '<thead><tr>'
		  for (let i = 0; i < th_len; i++) {
			thead += '<th>' + theadData[i] + '</th>'
			
		  }
		  thead += '</tr></thead>'
		 
		  // 添加每一行数据
		  let tbody = '<tbody>'
		  for (let i = 0; i < tb_len; i++) {
			tbody += '<tr ">'
			let row = tbodyData[i] // 获取每一行数据
		 
		 
			//循环遍历出每一行数据 ，row[key]值为列的值
			for (let key in row) {
			  if (re.test(row[key])) { // 如果为图片，则需要加div包住图片   //&#10;是换行符号可以在excel文件中换行,<div>是一个单元格,可以做合并单元格用
				// 图片
				tbody += '<td style="width:' + width + 'px; height:' + height + 'px; text-align: center; vertical-align: middle"><div style="display:inline"><img src=\'' + row[key] + '\' ' + ' ' + 'width=' + '\"' + width + '\"' + ' ' + 'height=' + '\"' + height + '\"' + '></div></td>'
			  } else {
				  //不是图片
				  
				  //如果当前列是数组那就多行显示
				  if(Array.isArray(row[key])){
					  
					  
					  tbody += '<td style=" text-align:center;text-align:center;mso-number-format:\@;" >';
					   
					   for (let item of row[key]) {
						   // 
							tbody +='<div style="white-space:nowrap;text-align:left;">';
								for (let key2 in item) {
									tbody +=item[key2]+'　　　'
								}
							
							tbody +='</div>';
					   }
					    
						tbody +='</td>';
				  }else{
					  //当前列不是数组
					  tbody += '<td style=" text-align:center;text-align:center;mso-number-format:\@;">' + row[key] + '</td>'
				  }
				
			  }
			}
			tbody += '</tr>'
		  }
		  tbody += '</tbody>'
		 
		  let table = thead + tbody
		 
		  // 导出表格
		  exportToExcel(table, dataname)
		}


		export default {export2Excel:export2Excel} 